import {
  HtmlValidationMessageOverrideObject,
  ValidationData,
} from './inputValidationTypes';
import { addErrorToValidationDataAndKeepHtmlMessage } from './validityUtils';

export const validatePhoneFormat = (
  props: {
    phoneFormat?: string;
    value: string;
  },
  validationData: ValidationData,
): ValidationData => {
  const { value, phoneFormat } = props;
  const phoneFormatWithoutSpacesLength = phoneFormat
    ? phoneFormat.replace(/-/g, '').length
    : 0;

  if (
    !phoneFormat ||
    (phoneFormat && value.length === phoneFormatWithoutSpacesLength)
  ) {
    return validationData;
  }

  let validationKey: 'tooShort' | 'tooLong';
  const htmlValidationMessageOverride: HtmlValidationMessageOverrideObject = {
    key: 'PHONE_FORMAT_LENGTH_VALIDATION_ERROR',
  };

  if (value.length < phoneFormatWithoutSpacesLength) {
    validationKey = 'tooShort';
  } else {
    validationKey = 'tooLong';
  }

  return addErrorToValidationDataAndKeepHtmlMessage(
    validationData,
    validationKey,
    htmlValidationMessageOverride,
  );
};
